@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=DM+Sans");

body {
  font-family: "Open Sans", sans-serif;
  color: #333;
}

p,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #333;
}

h1,
h2,
h3 {
  font-family: "DM Sans", sans-serif;
  color: #333;
  font-weight: 600;
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fffbf5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes colorCycle {
  0%,
  100% {
    color: rgb(255, 153, 200);
  }
  20% {
    color: rgb(255, 209, 102);
  }
  40% {
    color: rgb(201, 226, 101);
  }
  60% {
    color: rgb(255, 145, 77);
  }
  80% {
    color: rgb(86, 174, 255);
  }
}

.colorCycle {
  animation: colorCycle 12s infinite;
}

@keyframes backgroundCycle {
  0%,
  100% {
    background-color: rgb(255, 153, 200);
  }
  20% {
    background-color: rgb(255, 209, 102);
  }
  40% {
    background-color: rgb(201, 226, 101);
  }
  60% {
    background-color: rgb(255, 145, 77);
  }
  80% {
    background-color: rgb(86, 174, 255);
  }
}

.buttonColorCycle {
  animation: backgroundCycle 12s infinite;
  background-color: #fffbf5;
  border: 2px solid #fffbf5;
  color: #333;
  font-family: "DM Sans", sans-serif;
}

@keyframes bordercolorCycle {
  0%,
  100% {
    border-color: rgb(255, 153, 200);
  }
  20% {
    border-color: rgb(255, 209, 102);
  }
  40% {
    border-color: rgb(201, 226, 101);
  }
  60% {
    border-color: rgb(255, 145, 77);
  }
  80% {
    border-color: rgb(86, 174, 255);
  }
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #c4c1bc !important;
  border-width: 2px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #c4c1bc !important;
  border-width: 2px !important;
}

.gradientBackground {
  background: linear-gradient(
    to bottom right,
    rgb(255, 153, 200),
    rgb(255, 145, 77),
    rgb(255, 209, 102),
    rgb(201, 226, 101),
    rgb(86, 174, 255)
  );
  height: 100vh; /* Full height */
  width: 100vw; /* Default to full width on smaller screens */
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Media query for larger screens */
@media (min-width: 600px) {
  /* Adjust 600px to your breakpoint */
  .gradientBackground {
    width: 50vw; /* 50% width on larger screens */
  }
}

.success-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sparkle-icon {
  font-size: 48px; /* Adjust as needed */
  animation: sparkle 2s ease-in-out infinite; /* Apply sparkle animation */
}
